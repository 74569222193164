class ZIDXAccountListingIndexImport implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountListingIndexImportContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/listing-index/file/import";
    }
    render(){
        // console.log("listing index import");
        ZIDX.$(".zidxAccountListingIndexImportContainer .zidxForm").on("submit", function(e:SubmitEvent){
            if(window.confirm("All files and fields for this index will be deleted if you proceed with import. Proceed?")) {
                return true;
            }else{
                return false;
            }
        });
    }
}